#map-container {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* height: calc(100vh - 426px); */
  height: calc(100vh - 64px);
}

/* #global-legend {
  position: absolute;
  bottom: 30px;
  left: 10px;
  width: 125px;
  height: 200px;
  background-image: url('global_legend.png');
  background-size: 100% 100%;
} */

#risk-legend {
  position: absolute;
  top: 170px;
  right: 10px;
  width: 115px;
  height: 230px;
  background-image: url('risk_legend.png');
  background-size: 100% 100%;
}

#usa-legend {
  position: absolute;
  top: 345px;
  right: 10px;
  width: 115px;
  height: 180px;
  background-image: url('usa_legend.png');
  background-size: 100% 100%;
}

#zoom-level {
  position: absolute;
  top: 15px;
  right: 45px;
  width: 90px;
  height: 20px;
  background-color: white;
  border-radius: 5px; /* Rounded corners */
}

.mapboxgl-ctrl-geocoder {
  width: 400px!important;
  z-index: 1000; /* Increase this value */
}

.custom-popup-container {
  position: absolute;
  z-index: 1000; /* Make sure it's above other elements */
}

.custom-popup {
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  max-width: 300px;
}

/* Combined fade-in and pulse border effect */
.emphasized {
  animation: fadeInScale 0.5s ease-out forwards, pulseBorder 1.5s ease-out forwards;
}

/* Fade-in and scale effect */
@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.85); /* Start slightly smaller */
  }
  to {
    opacity: 1;
    transform: scale(1); /* End at full size */
  }
}

/* Border pulse effect */
@keyframes pulseBorder {
  0% {
    box-shadow: 0 0 0px rgba(0, 150, 255, 0.4); /* Soft blue glow */
  }
  50% {
    box-shadow: 0 0 10px rgba(0, 150, 255, 0.7); /* Bright blue glow */
  }
  100% {
    box-shadow: 0 0 0px rgba(0, 150, 255, 0.4); /* Return to soft glow */
  }
}


